import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-status-setting-feedback',
  templateUrl: './status-setting-feedback.component.html',
  styleUrls: ['./status-setting-feedback.component.scss']
})
export class StatusSettingFeedbackComponent implements OnInit {

  @Input() item: any;
  @Output() valueChanged = new EventEmitter<any>();
  @Output() valueChangedTwo = new EventEmitter<any>();

  public formulario: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    this.formulario = this.formBuilder.group({
      icon_type_id: [null, Validators.required],
      conclusive: [null, Validators.required],
      complementary_text: [null],
      cta_text: [null]
    });
  }

  ngOnInit(): void {
    if (typeof(this.item.cta_text) == 'string' && this.item.cta_text == 'null') {
      this.item.cta_text = '';
    }
    if (typeof(this.item.complementary_text) == 'string' && this.item.complementary_text == 'null') {
      this.item.complementary_text = '';
    }
    this.formulario.setValue({
      icon_type_id: this.item.icon_type_id ? this.item.icon_type_id : 1,
      conclusive: this.item.conclusive,
      complementary_text: this.item.complementary_text ? this.item.complementary_text : '',
      cta_text: this.item.cta_text ? this.item.cta_text : ''
    });
    this.onValueChanges();
    // this.valueChanged.emit(this.formulario);
  }

  changeIconType(val) {
    this.formulario.controls.icon_type_id.setValue(val);
  }

  onValueChanges(): void {
    this.formulario.valueChanges.subscribe(changes => {
      if (changes.conclusive === 1 && changes.icon_type_id === 3) {
        this.formulario.controls.icon_type_id.setValue(1);
      }
      this.valueChanged.emit(this.formulario);
      this.valueChangedTwo.emit({ changed: true, to: 'status', data: changes.background });
    });
  }
}
