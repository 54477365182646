
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/authentication/auth.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';

@Injectable()
export class AuthGuard  {

  private logged = false;

  constructor(private route: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): any {
    const requests: [Promise<any>] = [
      this.verifyUser()
    ];

    return Promise.all(requests).then((results: any[]) => {
      const response = this.routeAction();
      return response;
    });
  }

  private verifyUser(): any {
    const tokenPS = localStorage.getItem('tokenPS');
    if (tokenPS !== null && typeof tokenPS !== 'undefined') {
      this.logged = true;
    } else {
      this.logged = false;
    }
  }

  private routeAction() {
    if (this.logged) {
      return true;
    } else {
      this.route.navigate(['acesso-negado']);
    }
  }

  private canLoad(route: Route): Observable<boolean>|Promise<boolean>|boolean {
    console.log('canLoad: verificando se usuário pode carregar o cod módulo');
    return this.verifyUser();
  }

}
