import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl, FormControl, UntypedFormArray } from '@angular/forms';
import { StageSaveService } from '@app/services/selective-processes/stage/stage-save.service';
import { FormUtils } from '@shared/utils/form.utils';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/internal/operators/filter';
import { Observable } from 'rxjs';
import { DialogComponent } from '../../dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { LoaderService } from '@shared/components/loader/loader.service';
import { mapObjectByProperties, updateObjKeepingRefWithoutMapArray } from '@shared/utils/util';
import { SystemSettingService } from '@modules/administration/system-settings/system-setting.service';
import { SelectiveEditorService } from '@modules/administration/selective-editor/selective-editor.service';
import { StageQueryService } from '@app/services/selective-processes/stage/stage-query.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FeedbackActionsMultipleService } from '@app/services/selective-processes/feedback/feedback-actions-multiple.service';
import { FieldStandardService } from '@app/services/selective-processes/fields/fields-standard.service';

export interface RubeusFields {
  name: string;
  value: any;
}
@Component({
  selector: 'app-dialog-add-stage',
  templateUrl: './dialog-add-stage.component.html',
  styleUrls: ['./dialog-add-stage.component.scss']
})
export class DialogAddStageComponent implements OnInit, AfterContentChecked {
  public formulario: UntypedFormGroup;
  public typeField;
  public idPs;
  public query: any = {active_paramn: false};
  public queryCalled: any = false;
  public formControlRef: UntypedFormArray;
  filteredFields: Observable<RubeusFields[]>[] = [];
  availableFieldOptions: RubeusFields[] = new Array();
  public fieldJson = {
    components : [{}]
  };
  public dataComponent: any;
  public unsavedGeneral: any;
  public unsavedLogic: any;

  public dataSP;
  public dataCurrentStage;
  public steps = [];
  public feedbacks = [];
  public orderChanged = false;
  public allFields = [];

  @ViewChild('tabGroup', { static: true }) tabs: MatTabGroup;

  constructor(
    private stageSaveService: StageSaveService,
    public dialogRef: MatDialogRef<DialogAddStageComponent>,
    private formBuilder: UntypedFormBuilder,
    private route: Router,
    public dateComponent: DialogComponent,
    public loaderService: LoaderService,
    public systemSettingService: SystemSettingService,
    public selectiveEditorService: SelectiveEditorService,
    public stageQueryService: StageQueryService,
    private feedbackActionsMultipleService: FeedbackActionsMultipleService,
    private fieldStandardService: FieldStandardService
  ) {
    this.formulario = this.formBuilder.group({
      general : this.formBuilder.group({
        id: [null],
        selective_process_id: [null],
        title: ['', [Validators.required, Validators.maxLength(25)]],
        status: [false],
        position: [null],
        steps: [],
      }),
      logic: this.formBuilder.group({
        logic_enabled: [null],
        action_logic_id: [null],
        condition_logic_id: [null],
        logics: [null, [this.validatorLogics]]
      }),
      dataSource: this.formBuilder.group({
        changed: [false]
      })
    });
    this.formulario.markAsDirty();
  }

  async ngOnInit() {
    this.dataSP = this.selectiveEditorService.getData();
    await this.loadAllFields();
    this.dataCurrentStage = this.selectiveEditorService.getDataStageID(this.dateComponent.data.dataStage.id);
    this.buildingDialogUpdate();
    this.query.standardFields = this.dateComponent.data.keys;
    this.availableFieldOptions = this.dateComponent.data.keys;
    this.systemSettingService.getMatTabGroup(this.tabs);
    this.dateComponent.data.teste = JSON.parse(JSON.stringify(this.dataCurrentStage));
    this.steps = JSON.parse(JSON.stringify(this.dataCurrentStage.steps));
    this.feedbacks = JSON.parse(JSON.stringify(this.dataCurrentStage.feedback));
    this.feedbacks.forEach((feedback, i) => {
      feedback.initialPosition = i;
    });
  }

  drop(event: CdkDragDrop<string[]>, type) {
    this.orderChanged = true;
    if (type === "step") {
      moveItemInArray(this.steps, event.previousIndex, event.currentIndex);
      this.steps.forEach((step, i) => {
        step.position = i;
      });
    } else {
      moveItemInArray(this.feedbacks, event.previousIndex, event.currentIndex);
      this.feedbacks.forEach((feedback, i) => {
        feedback.position = i;
      });
    }
  }

  validatorLogics(input) {
    if (!input.value) {
      return null;
    }

    function checkLogicRuleValue(logic) {
      if ([3, 4].includes(logic.rule_logic_id)) {
        return true;
      }
      if (logic.fixed_value !== undefined && logic.fixed_value !== null) {
        return true;
      }
      return Boolean(logic.fixed_value);
    }

    const logicValid = input.value.every(logic => {
      return logic.field_compare_id && logic.rule_logic_id && checkLogicRuleValue(logic);
    })

    if (!logicValid) {
      return {
        logicInvalid: true,
      }
    }
    return null;
  }

  private isStepNameDefaultPattern(name:string = '') {
    const stepNameSplit = name.split(' ');
    if (stepNameSplit.length === 2 && stepNameSplit[0] === 'Passo' && Number(stepNameSplit[1])) {
      return true;
    }

    return false;
  }

  private async loadAllFields() {
    this.allFields = await this.fieldStandardService.getFields();
  }

  ngAfterContentChecked(): void {
    // this.cdref.detectChanges();
  }

  onSubmit() {
    this.loaderService.setChangesLoaderState(true);
    const dataFormatted = FormUtils.switchValueSlideToggleAPI(['status'], this.formulario.getRawValue().general);
    dataFormatted.selective_process_id = this.dataSP.selectiveId;
    dataFormatted.action_logic_id = this.formulario.getRawValue().logic.action_logic_id;
    dataFormatted.condition_logic_id = this.formulario.getRawValue().logic.condition_logic_id;
    dataFormatted.logic_enabled = this.formulario.getRawValue().logic.logic_enabled;
    dataFormatted.logics = this.formulario.getRawValue().logic.logics;
    dataFormatted.stages_positions = this.dateComponent.data.dataStage.stages_positions;
    this.steps.forEach((step, i) => {
      if(this.isStepNameDefaultPattern(step.name)) {
        step.name = `Passo ${i + 1}`;
      }
    });
    dataFormatted.steps = this.steps;
    if (this.feedbacks.length > 0) {
      dataFormatted.feedback = this.feedbacks;
    }
    if (typeof this.dataCurrentStage.id === 'undefined') {
      this.stageSaveService.create(dataFormatted).subscribe(
        success => {
          if (success.success) {
            const data = success.data;
            this.formulario.controls.general.setValue({
              id: data.id,
              position: data.position,
              selective_process_id: data.selective_process_id,
              status: data.status,
              steps: null,
              title: data.title
            });
            this.unsavedGeneral = false;
            this.unsavedLogic = false;
            if (typeof this.dateComponent.data.changed !== 'undefined') {
              const obj = this.dateComponent.data.changed.find(o => o.type === 'general');
              const objL = this.dateComponent.data.changed.find(o => o.type === 'logic');
              if (typeof obj !== 'undefined') {
                obj.alt = false;
              }
              if (typeof objL !== 'undefined') {
                objL.alt = false;
              }
            }
            this.tabs.realignInkBar();
            this.query.stage_id = success.data.id;
            this.query.id = success.data.query.id;
            success.data.status = success.data.status ? 1 : 0;
            this.dataSP.stages.push(success.data);
            this.dataCurrentStage = this.selectiveEditorService.getDataStageID(success.data.id);
            this.redirectionStage(this.dataSP.stages);

            this.formulario.patchValue(FormUtils.switchValueSlideToggleView(['status'], success.data));
            this.loaderService.setChangesLoaderState(
              false,
              'Etapa criada com sucesso',
              'ok',
              'success'
            );
          } else {
            this.loaderService.setChangesLoaderState(
              false,
              'Não foi possível criar etapa, tente novamente mais tarde !',
              'ok',
              'alert'
            );
          }
        },
        error => {
          this.loaderService.setChangesLoaderState(false,
            error.body,
            '',
            'error',
            error.title,
            100000);
        }
      );
    } else { // edição
      this.stageSaveService.create(dataFormatted).subscribe(
        success => {
          if (success.success) {
            this.dateComponent.data.scheduledClosing = false;
            this.unsavedGeneral = false;
            this.unsavedLogic = false;
            if (typeof this.dateComponent.data.changed !== 'undefined') {
              const obj = this.dateComponent.data.changed.find(o => o.type === 'general');
              const objL = this.dateComponent.data.changed.find(o => o.type === 'logic');
              if (typeof obj !== 'undefined') {
                obj.alt = false;
              }
              if (typeof objL !== 'undefined') {
                objL.alt = false;
              }
            }
            this.tabs.realignInkBar();
            updateObjKeepingRefWithoutMapArray(this.dataCurrentStage, success.data);
            this.loaderService.setChangesLoaderState(
              false,
              'Salvo com sucesso',
              'ok',
              'success'
            );
            if (this.orderChanged) {
              if (dataFormatted.feedback && dataFormatted.feedback.length > 0) {
                this.feedbackActionsMultipleService.createArray(dataFormatted.feedback).subscribe(
                  success => {
                    if (Array.isArray(success)) {
                      this.loaderService.setChangesLoaderState(
                        false,
                        'Feedback salvo com sucesso',
                        'ok',
                        'success'
                      );
                      this.dialogRef.close();
                      window.location.reload();
                    } else {
                      this.loaderService.setChangesLoaderState(
                        false,
                        'Não foi possível salvar os feedbacks, tente novamente mais tarde !',
                        'ok',
                        'alert'
                      );
                    }
                  },
                  error => {
                    this.loaderService.setChangesLoaderState(
                      false,
                      error.body,
                      '',
                      'error',
                      error.title,
                      100000
                    );
                  }
                );
              } else {
                this.dialogRef.close();
                window.location.reload();
              }
            }
          } else {
            this.loaderService.setChangesLoaderState(
              false,
              'Não foi possível editar etapa, tente novamente mais tarde !',
              'ok',
              'alert'
            );
          }
        },
        error => {
          this.loaderService.setChangesLoaderState(
            false,
            error.body,
            '',
            'error',
            error.title,
            100000
          );
        }
      );
    }
  }

  private redirectionStage(data) {
    if (data.length === 1) {
      data.filter(value => {
        if (value.position === 0) {
          this.route.navigate([`administrativo/processo-seletivo/etapa/${value.selective_process_id}-${value.id}`]);
        }
      });
    }
  }

  buildingDialogUpdate() {
    if (typeof this.dataCurrentStage.id !== 'undefined') {
      const dataFormatted = FormUtils.switchValueSlideToggleView(['status'],
      JSON.parse(JSON.stringify(this.dataCurrentStage)));
      this.buildDataGeneral(dataFormatted);
      // this.formulario.patchValue(dataFormatted);
      this.stageQueryService.read(this.dataCurrentStage.id).subscribe((success) => {
        if (success.success) {
          this.queryCalled = true;
          this.query = Object.assign(this.query, success.data.query);
        } else {
          this.queryCalled = true;
        }
      }, error => { this.queryCalled = true; });
    } else {
      this.queryCalled = true;
    }
  }

  private buildDataGeneral(dataFormatted) {
    const data = mapObjectByProperties(
    dataFormatted,
    [
      'id',
      'selective_process_id',
      'title',
      'status',
      'position',
      'steps'
    ]);
    this.formulario.controls.general.patchValue(data);
    this.formulario.controls.general.valueChanges.subscribe(
      () => {
        this.unsavedGeneral = true;
        this.tabs.realignInkBar();
        this.dateComponent.data.scheduledClosing = true;
        if (typeof this.dateComponent.data.changed === 'undefined') {
          this.dateComponent.data.changed = [];
        }
        if (this.dateComponent.data.changed.length > 0) {
          const obj = this.dateComponent.data.changed.find(o => o.type === 'general');
          if (typeof obj === 'undefined') {
            this.dateComponent.data.changed.push({ type: 'general', alt: true });
          } else {
            obj.alt = true;
          }
        } else {
          this.dateComponent.data.changed = [{ type: 'general', alt: true }];
        }
      }
    );
  }

  private buildingStages() {
    this.firstTimeIn();
    this.route.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      const eventPop = event.url.split('/').pop();
      if (eventPop.indexOf('-') !== -1) {
        this.idPs = parseInt(eventPop.split('-')[0], 0);
      } else {
        this.idPs = parseInt(eventPop, 0);
      }
    });
  }

  private firstTimeIn() {
    const routePop = this.route.url.split('/').pop();
    if (routePop.indexOf('-') !== -1) {
      this.idPs = parseInt(routePop.split('-')[0], 0);
    } else {
      this.idPs = parseInt(routePop, 0);
    }
  }

  private setValueForm(date) {
    const control: any = this.dateComponent.data.formulario.controls.stage;
    control.push(this.patchValuesForm(date));
    this.dateComponent.data.dataStage = date;
  }

  private patchValuesForm(date) {
    return this.formBuilder.group(date);
  }

  private updateValue(date) {
    const control: any = this.dateComponent.data.formulario.controls.stage;
    control.value.filter((x) => {
      if (x.id === date.id) {
        Object.assign(x, date);
      }
    });
    this.dateComponent.data.dataStage = date;
  }

  get g() {
    const control: any = this.formulario.get('general');
    return control.controls;
  }

  get l() {
    const control: any = this.formulario.get('logic');
    return control.controls;
  }

  get d() {
    const control: any = this.formulario.get('dataSource');
    return control.controls;
  }

  public logicChanged(obj: any) {
    this.fieldJson.components[0] = obj;
    this.l.logic_enabled.setValue(obj.logic_enabled);
    this.l.action_logic_id.setValue(obj.action_logic_id);
    this.l.condition_logic_id.setValue(obj.condition_logic_id);
    this.l.logics.setValue(obj.logics);
    this.formulario.controls.logic.valueChanges.subscribe(
      () => {
        this.unsavedLogic = true;
        this.tabs.realignInkBar();
        this.dateComponent.data.scheduledClosing = true;
        if (typeof this.dateComponent.data.changed === 'undefined') {
          this.dateComponent.data.changed = [];
        }
        if (this.dateComponent.data.changed.length > 0) {
          const obje = this.dateComponent.data.changed.find(o => o.type === 'logic');
          if (typeof obje === 'undefined') {
            this.dateComponent.data.changed.push({ type: 'logic', alt: true });
          } else {
            obj.alt = true;
          }
        } else {
          this.dateComponent.data.changed = [{ type: 'logic', alt: true }];
        }
      }
    );
  }
}
