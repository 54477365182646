<mat-tab-group mat-stretch-tabs="false"
  *ngIf="haveDataSource"
  #matTabGroup
  [animationDuration]="'0ms'"
  (animationDone)="actionTabs(matTabGroup)"
  class="ps-data-source-settings-feed-matGroup">
  <mat-tab *ngFor="let tab of dataSource | keyvalue">
    <ng-template mat-tab-label>
      Fonte {{(tab.key | toNumber) + 1}}
    </ng-template>
    <div class="ps-data-source-settings-feed-container">
      <!-- Key: <b>{{tab.key}}</b> and Value: <b>{{tab.value}} {{dataSource.length}}</b> -->
      <!-- <app-totvs-query-fields [dataSource]="tab.value | removeValueQuery"
        [displayedColumns]="['title', 'description']">
      </app-totvs-query-fields> -->
      <app-totvs-query-fields
        [fields]="dataSourceFeedback.dados.scope.feedbackFields"
        [dataSource]="tab.value"
        [displayedColumns]="['title', 'description']">
      </app-totvs-query-fields>
    </div>
    <form [formGroup]="formulario" (ngSubmit)="onSubmit(tab.value)">
      <div fxLayout="row" class="ps-data-source-settings-feed-container" fxLayoutGap="20px">
        <mat-form-field appearance="fill" fxFlex="18">
          <mat-label>Coligada</mat-label>
          <input matInput #input formControlName="colligate" required>
          <mat-error *ngIf="f.colligate.hasError('required')">
            Campo obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="18">
          <mat-label>Cod Sistema</mat-label>
          <input matInput #input formControlName="system" required>
          <mat-error *ngIf="f.system.hasError('required')">
            Campo obrigatório
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex="18">
          <mat-label>Cod Consulta</mat-label>
          <input matInput #input formControlName="code" required>
          <mat-error *ngIf="f.code.hasError('required')">
            Campo obrigatório
          </mat-error>
        </mat-form-field>
        <mat-slide-toggle
          color="primary"
          formControlName="use_cache">
          Utilizar cache
        </mat-slide-toggle>
        <mat-form-field *ngIf="formulario.controls.use_cache.value" appearance="fill" fxFlex="20">
          <mat-label>Frequência</mat-label>
          <mat-select formControlName="cache_interval_type_id">
            <mat-option *ngFor="let interval of intervals" [value]="interval.value">{{interval.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <div fxFlex="100">
          <mat-divider></mat-divider>
        </div>
      </div>
      <div class="ps-data-source-settings-feed-container">
        <div class="ps-send-param-query-totvs-content" fxLayout="row">
          <div fxFlex>
            <mat-slide-toggle
              color="primary"
              #matSlideToggleContext
              (toggleChange)="changeToggle(matSlideToggleContext, 'active_context')"
              formControlName="active_context">
              Enviar contexto
            </mat-slide-toggle>
          </div>
        </div>
        <div formArrayName="context" *ngFor="let item of c.controls; let i = index;">
          <div *ngIf="matSlideToggleContext.checked" [formGroupName]="i" class="fade-in-bottom ps-row" fxLayout="row"
          fxLayoutGap="20px">
            <mat-form-field
              appearance="fill"
              fxFlex="20">
              <mat-label>Nome campo</mat-label>
              <mat-select
                formControlName="name"
                [errorStateMatcher]="matcher">
                <mat-option value="CODCOLIGADA">
                  CODCOLIGADA
                </mat-option>
            </mat-select>
            <mat-error *ngIf="item.get('name').hasError('required')">
              Campo obrigatório
            </mat-error>
          </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Tipo do campo</mat-label>
              <mat-select (selectionChange)="changeParameterTypeId('context')"  formControlName="parameter_type_id" [errorStateMatcher]="matcher">
                <mat-option value="1">Campo sistema</mat-option>
                <mat-option value="2">Valor fixo</mat-option>
              </mat-select>
              <mat-error *ngIf="item.get('parameter_type_id').hasError('required')">
                Campo obrigatório
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="item.get('parameter_type_id').value == '2'" appearance="fill" fxFlex="20">
              <mat-label>Valor fixo</mat-label>
              <input matInput #input formControlName="fixed_value" [errorStateMatcher]="matcher">
              <mat-error *ngIf="item.get('fixed_value').hasError('valueFixedValue')">
                Campo obrigatório
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="item.get('parameter_type_id').value == '1'" appearance="fill" fxFlex="20">
              <mat-label>Campo sistema</mat-label>
              <input
                type="text"
                matInput
                formControlName="field_id"
                [errorStateMatcher]="matcher"
                [matAutocomplete]="auto">
              <mat-autocomplete
                #auto="matAutocomplete"
                autoActiveFirstOption="true"
                [displayWith]="displayFn">
                <mat-option
                *ngFor="let option of filteredOptions[i] | async"
                [value]="option"
                [matTooltip]="option.label"
                matTooltipPosition="below"
                matTooltipClass="ps-tooltip-content"
                [matTooltipShowDelay]="200"
                >
                  {{option.label}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="item.get('field_id').hasError('valueFieldId')">
                Campo obrigatório
              </mat-error>
              <mat-error
                *ngIf="item.get('field_id').hasError('match') && !item.get('field_id').hasError('valueFieldId')">
                Selecione o campo
              </mat-error>
            </mat-form-field>
            <div fxLayoutGap="15px" fxFlex fxLayoutAlign="start center" class="ps-btn-send-param-query-totvs-content">
              <a
                class="ps-send-param-query-totvs-del"
                color="primary"
                mat-stroked-button
                (click)="removeItem(i, 'context')">
                <mat-icon>delete</mat-icon>
              </a>
              <a
                class="ps-send-param-query-totvs-add"
                color="primary"
                mat-stroked-button
                (click)="addItem([],'context')"
                disabled>
                <mat-icon>add</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="ps-data-source-settings-feed-container">
        <div class="ps-send-param-query-totvs-content" fxLayout="row">
          <div fxFlex>
            <mat-slide-toggle
              color="primary"
              #matSlideToggle="matSlideToggle"
              (toggleChange)="changeToggle(matSlideToggle, 'active_paramn')"
              formControlName="active_paramn">
              Enviar parâmetros
            </mat-slide-toggle>
          </div>
        </div>
        <div formArrayName="parameters" *ngFor="let item of p.controls; let i = index;">
          <div *ngIf="matSlideToggle.checked" [formGroupName]="i" class="fade-in-bottom ps-row" fxLayout="row"
          fxLayoutGap="20px">
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Nome campo</mat-label>
              <input matInput #input formControlName="name" [errorStateMatcher]="matcher">
              <mat-error *ngIf="item.get('name').hasError('required')">
                Campo obrigatório
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" fxFlex="20">
              <mat-label>Tipo do campo</mat-label>
              <mat-select (selectionChange)="changeParameterTypeId('parameters')"  formControlName="parameter_type_id" [errorStateMatcher]="matcher">
                <mat-option value="1">Campo sistema</mat-option>
                <mat-option value="2">Valor fixo</mat-option>
              </mat-select>
              <mat-error *ngIf="item.get('parameter_type_id').hasError('required')">
                Campo obrigatório
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="item.get('parameter_type_id').value == '2'" appearance="fill" fxFlex="20">
              <mat-label>Valor fixo</mat-label>
              <input matInput #input formControlName="fixed_value" [errorStateMatcher]="matcher">
              <mat-error *ngIf="item.get('fixed_value').hasError('valueFixedValue')">
                Campo obrigatório
              </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="item.get('parameter_type_id').value == '1'" appearance="fill" fxFlex="20">
              <mat-label>Campo sistema</mat-label>
              <input
                type="text"
                matInput
                formControlName="field_id"
                [errorStateMatcher]="matcher"
                [matAutocomplete]="auto">
              <mat-autocomplete
                #auto="matAutocomplete"
                autoActiveFirstOption="true"
                [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredOptions[i] | async" [value]="option">
                  {{option.label}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="item.get('field_id').hasError('valueFieldId')">
                Campo obrigatório
              </mat-error>
              <mat-error
                *ngIf="item.get('field_id').hasError('match') && !item.get('field_id').hasError('valueFieldId')">
                Selecione o campo
              </mat-error>
            </mat-form-field>
            <div fxLayoutGap="15px" fxFlex fxLayoutAlign="start center" class="ps-btn-send-param-query-totvs-content">
              <a
                class="ps-send-param-query-totvs-del"
                color="primary"
                mat-stroked-button
                (click)="removeItem(i, 'parameters')">
                <mat-icon>delete</mat-icon>
              </a>
              <a
                class="ps-send-param-query-totvs-add"
                color="primary"
                mat-stroked-button
                (click)="addItemSpecificPosition('parameters', i)">
                <mat-icon>add</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
      <app-save-dialog [form]="formulario"></app-save-dialog>
    </form>
  </mat-tab>
</mat-tab-group>
<div *ngIf="!haveDataSource" style="padding: 24px;">
  <p class="ps-title-info-large">Atenção!</p>
  <p class="ps-reading-15">Apenas campos marcados com "Opções predefinidas" poderão ter sua fonte de dados configurada.
  </p>
</div>
