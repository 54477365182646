import { IFormCanDeactivate } from './iform-candeactivate';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class AuthDeactivateGuard  {

  constructor() { }

  canDeactivate(
    component: IFormCanDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component === null) {
      return true;
    } else {
      return component.canDisable ? component.canDisable() : true;
    }
  }
}
