<div class="content-dialog-feedback">
  <mat-tab-group mat-stretch-tabs="false"
    #tabGroup="matTabGroup"
    [animationDuration]="'0ms'"
    class="ps-dialog-config-feed-matgroup"
    [ngClass]="{'ps-dialog-config-feed-matgroup-data': selectedTab.id === 'data'}"
    (selectedTabChange)="tabChange($event)">
    <mat-tab>
       <ng-template mat-tab-label>
        <span>Estilo</span>
        <button
          [ngClass]="{'ps-sp-tabs-feed-tooltip-isActive pulse-sp-feed-tabs': unsavedStyle}"
          mat-icon-button
          class="ps-sp-tabs-feed-tooltip"
          matTooltip="Alterações não foram salvas"
          matTooltipPosition="after"
          matTooltipClass="ps-tooltip-content">
          <mat-icon svgIcon="alert"></mat-icon>
        </button>
      </ng-template>
      <!-- <ng-template> -->
        <div class="ps-dialog-config-feed-container">
          <app-style-setting-feedback
            (valueChanged)="valueChange($event)"
            [feedbackCurrent]="feedbackCurrent"
            [backgroundPortal]="dataSP.visualIdentity.background">
          </app-style-setting-feedback>
        </div>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Status</span>
        <button
          [ngClass]="{'ps-sp-tabs-feed-tooltip-isActive pulse-sp-feed-tabs': unsavedStatus}"
          mat-icon-button
          class="ps-sp-tabs-feed-tooltip"
          matTooltip="Alterações não foram salvas"
          matTooltipPosition="after"
          matTooltipClass="ps-tooltip-content">
          <mat-icon svgIcon="alert"></mat-icon>
        </button>
      </ng-template>
      <!-- <ng-template> -->
        <div class="ps-dialog-config-feed-container ps-dialog-config-feed-nopadding-container">
          <app-status-setting-feedback
            [item]="feedbackCurrent"
            (valueChangedTwo)="valueChange($event)"
            (valueChanged)="statusChanged($event)">
          </app-status-setting-feedback>
        </div>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Lógica</span>
        <button
          [ngClass]="{'ps-sp-tabs-feed-tooltip-isActive pulse-sp-feed-tabs': unsavedLogic}"
          mat-icon-button
          class="ps-sp-tabs-feed-tooltip"
          matTooltip="Alterações não foram salvas"
          matTooltipPosition="after"
          matTooltipClass="ps-tooltip-content">
          <mat-icon svgIcon="alert"></mat-icon>
        </button>
      </ng-template>
      <!-- <ng-template> -->
        <div class="ps-dialog-config-feed-container">
          <app-edit-form-logic
            [item]="{data: feedbackCurrent}"
            [keys]="dataComponent.data.keys"
            (valueChanged)="logicChanged($event)"
            fxFlex="100"
            [currentForm]="fieldJson">
          </app-edit-form-logic>
        </div>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab #currentTab>
      <ng-template mat-tab-label>
        <span>Fonte de dados</span>
        <button
          [ngClass]="{'ps-sp-tabs-feed-tooltip-isActive pulse-sp-feed-tabs': unsavedDataSource}"
          mat-icon-button
          class="ps-sp-tabs-feed-tooltip"
          matTooltip="Alterações não foram salvas"
          matTooltipPosition="after"
          matTooltipClass="ps-tooltip-content">
          <mat-icon svgIcon="alert"></mat-icon>
        </button>
      </ng-template>
      <ng-template matTabContent>
        <div style="height: 100%;">
          <app-data-source-setting-feedback
            [tabGroup]="tabGroup"
            (valueChanged)="valueChange($event)"
            [dataSourceFeedback]="dateComponent"
            [tab]="currentTab.isActive">
          </app-data-source-setting-feedback>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- <div class="footer-dialog-feedback" *ngIf="selectedTab.id != 'style'">
  <mat-divider style="width: 100%;"></mat-divider>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="21px">
    <button mat-stroked-button (click)="cancel()">
      Cancelar
    </button>
    <button mat-button (click)="save()" color="primary">
      Salvar
    </button>
  </div>
</div> -->

<mat-dialog-actions class="ps-dialog-container-actions"
  *ngIf="selectedTab.id != 'data'">
  <div fxLayout="row">
    <div fxFlex="100">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div fxLayout="row" fxFill style='margin-top: 2px;'>
    <div fxFlex fxLayoutAlign="end center" class="ps-dialog-content-actions">
      <a mat-stroked-button (click)="cancel()">
        Cancelar
      </a>
      <button mat-raised-button (click)="save()" color="primary" [disabled]="invalidLogic">
        Salvar
      </button>
    </div>
  </div>
</mat-dialog-actions>
