<mat-tab-group mat-stretch-tabs="false"
  #tabGroup
  [animationDuration]="'0ms'"
  class="ps-dialog-config-stage-matgroup">
  <mat-tab>
    <ng-template mat-tab-label>
      <span>Geral</span>
      <button
        [ngClass]="{'ps-sp-tabs-stage-tooltip-isActive pulse-sp-tabs': unsavedGeneral}"
        mat-icon-button
        class="ps-sp-tabs-stage-tooltip"
        matTooltip="Alterações não foram salvas"
        matTooltipPosition="after"
        matTooltipClass="ps-tooltip-content">
        <mat-icon svgIcon="alert"></mat-icon>
      </button>
    </ng-template>
    <form [formGroup]="formulario.controls.general" (ngSubmit)="onSubmit()">
      <div class="ps-dialog-config-stage-container">
        <div fxLayout="row" class="ps-row">
          <mat-form-field appearance="fill" fxFlex="50">
            <mat-label>Nome da etapa</mat-label>
            <input
              maxlength="25"
              #inputTitle
              matInput
              type="text"
              required
              formControlName="title">
            <mat-error *ngIf="g.title.hasError('required')">
              Campo obrigatório
            </mat-error>
            <mat-hint align="end">{{inputTitle.value.length}} / 25</mat-hint>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <div class="ps-dialog-config-stage-status">
            <span class="ps-label-slide-toggle">Status</span>
            <mat-slide-toggle
              style="margin-top: 10px;"
              formControlName="status"
              color="primary"
              #matSlideToggleStatus="matSlideToggle">
              {{ matSlideToggleStatus.checked ? 'Ativado' : 'Desativado' }}
            </mat-slide-toggle>
          </div>
        </div>

        <div style="margin-top: 10px;" *ngIf="steps.length > 1">
          <span _ngcontent-dee-c458="" style="color: #808080; padding-bottom: 10px;">Ordenação dos passos</span>

          <div cdkDropList cdkDropListOrientation="horizontal" class="steps-list" (cdkDropListDropped)="drop($event, 'step')">
            <div class="cdk-drag-placeholder" *cdkDragPlaceholder></div>
            <div [title]="step.name" class="steps-list-box" *ngFor="let step of steps" cdkDrag>{{step.name}}</div>
          </div>
        </div>

        <div style="margin-top: 10px;" *ngIf="feedbacks.length > 1">
          <span _ngcontent-dee-c458="" style="color: #808080; padding-bottom: 10px;">Ordenação dos feedbacks</span>

          <div cdkDropList cdkDropListOrientation="horizontal" class="steps-list" (cdkDropListDropped)="drop($event, 'feedback')">
            <div class="cdk-drag-placeholder" *cdkDragPlaceholder></div>
            <div [title]="'Feedback ' + (feedback.initialPosition + 1)" class="steps-list-box" *ngFor="let feedback of feedbacks" cdkDrag>{{'Feedback ' + (feedback.initialPosition + 1)}}</div>
          </div>
        </div>

      </div>
      <app-save-dialog
        [scheduledClosing]="unsavedGeneral"
        [form]="formulario.controls.general">
      </app-save-dialog>
    </form>
  </mat-tab>
  <mat-tab [disabled]="g.id.value === null">
     <ng-template mat-tab-label>
      <span>Fonte de dados</span>
      <button
        [ngClass]="{'ps-sp-tabs-stage-tooltip-isActive pulse-sp-tabs': d.changed.value}"
        mat-icon-button
        class="ps-sp-tabs-stage-tooltip"
        matTooltip="Alterações não foram salvas"
        matTooltipPosition="after"
        matTooltipClass="ps-tooltip-content">
        <mat-icon svgIcon="alert"></mat-icon>
      </button>
    </ng-template>
    <app-data-source-setting-stage
      *ngIf="queryCalled"
      [systemFields]="allFields"
      [dataDialog]="dateComponent"
      [formControlDataSource]="d"
      [dataSource]="query">
    </app-data-source-setting-stage>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span>Lógica</span>
      <button
        [ngClass]="{'ps-sp-tabs-stage-tooltip-isActive pulse-sp-tabs': unsavedLogic}"
        mat-icon-button
        class="ps-sp-tabs-stage-tooltip"
        matTooltip="Alterações não foram salvas"
        matTooltipPosition="after"
        matTooltipClass="ps-tooltip-content">
        <mat-icon svgIcon="alert"></mat-icon>
      </button>
    </ng-template>
    <form [formGroup]="formulario" (ngSubmit)="onSubmit()">
      <app-edit-form-logic
        [item]="{ data : dateComponent.data.dataStage}"
        [keys]="dateComponent.data.keys"
        (valueChanged)="logicChanged($event)"
        fxFlex="100"
        [currentForm]="fieldJson">
      </app-edit-form-logic>
      <app-save-dialog [form]="formulario"></app-save-dialog>
    </form>
  </mat-tab>
</mat-tab-group>
