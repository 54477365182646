import { ValidatorFn, AbstractControl, FormArray, UntypedFormGroup } from '@angular/forms';
import { isEmpty } from '@shared/utils/util';

export class DataSourceTypeFieldValidator {

  // max length
  // tslint:disable: radix
  public static valueFieldId(): ValidatorFn | any {
    return (control: UntypedFormGroup) => {
      if (control.parent) {
        const formcontrol: any = control.parent.controls;
        if (parseInt(formcontrol.parameter_type_id.value) === 1) {
          if (!isEmpty(formcontrol.field_id.value)) {
            return null;
          } else {
            return { valueFieldId: true };
          }
        } else {
          return null;
        }
      }
    };
  }

  public static valueFixedValue(): ValidatorFn | any {
    return (control: UntypedFormGroup) => {
      if (control.parent) {
        const formcontrol: any = control.parent.controls;
        if (parseInt(formcontrol.parameter_type_id.value) ===  2) {
          if (!isEmpty(formcontrol.fixed_value.value)) {
            return null;
          } else {
            return { valueFixedValue: true };
          }
        } else {
          return null;
        }
      }
    };
  }
}
