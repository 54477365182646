import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { SaveStageSaveService } from '@app/services/selective-processes/stage/stage-query-save.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { SlideToggleValidator } from '@shared/validators/slide-toggle-validator';
import { SourceFieldsValidator } from '@shared/validators/source-fields-validator';
import { AddsPareamentsService } from '@shared/components/adds-pareaments/adds-pareaments.service';
import { Fields } from '@app/services/selective-processes/fields/fields';
import { Observable } from 'rxjs';
import { updateObjKeepingRefWithoutMapArray } from '@shared/utils/util';

@Component({
  selector: 'app-data-source-setting-stage',
  templateUrl: './data-source-setting-stage.component.html',
  styleUrls: ['./data-source-setting-stage.component.scss']
})
export class DataSourceSettingStageComponent implements OnInit {

  @Input() dataSource: any;
  @Input() dataDialog: any;
  @Input() formControlDataSource: any;
  @Input() systemFields = [];

  public parameters: UntypedFormArray;
  public context: UntypedFormArray;
  public contextConfig = {
    control_name: 'context',
    toggle_name: 'active_context'
  }
  public nameFieldsContext = [
    {name: 'CODCOLIGADA', disabled: false}
  ];

  public formulario: UntypedFormGroup;
  private queryType: any = 2;
  private localType: any = 5;
  public intervals = [
    {value: 1, name: '15 minutos'},
    {value: 2, name: '30 minutos'},
    {value: 3, name: '1 hora'},
    {value: 4, name: '2 horas'},
    {value: 5, name: '3 horas'},
    {value: 6, name: '6 horas'},
    {value: 7, name: '12 horas'},
    {value: 8, name: 'Diário'},
    {value: 9, name: 'Semanal'}
  ];


  constructor(
    private formBuilder: UntypedFormBuilder,
    private serviceQuery: SaveStageSaveService,
    public loaderService: LoaderService,
    public addsPareamentsService:AddsPareamentsService) {
  }

  ngOnInit(): void {
    this.formulario = this.formBuilder.group({
      colligate: [this.dataSource.colligate],
      system: [this.dataSource.system],
      code: [this.dataSource.code],
      [this.contextConfig.toggle_name]: [false],
      [this.contextConfig.control_name]: this.formBuilder.array([], SlideToggleValidator.slideToggle()),
      active_paramn: [false],
      use_cache: [this.dataSource.use_cache],
      cache_interval_type_id: [this.dataSource.cache_interval_type_id],
      parameters: this.formBuilder.array([this.createItem({id: '', name: [''], parameter_type_id: '', field_id: '', fixed_value: ''})])
    });
    this.formulario.setValidators(SourceFieldsValidator.validateSourceFields());
    if (typeof this.dataSource.parameters !== 'undefined') {
      let itemsProcessed = 0;
      this.dataSource.parameters.forEach(i => {
        i.parameter_type_id = String(i.parameter_type_id);
        this.addItem(i);
        itemsProcessed++;
        if (itemsProcessed === this.dataSource.parameters.length) {
          this.formulario.patchValue({ active_paramn: true });
          this.removeItem(0);
        }
      });
    }
    this.controlValuesContext(this.dataSource, this.contextConfig);
    this.formulario.valueChanges.subscribe(
      () => {
        this.formControlDataSource.changed.patchValue(true);
        this.dataDialog.data.scheduledClosing = true;

        if (typeof this.dataDialog.data.changed === 'undefined') {
          this.dataDialog.data.changed = [];
        }
        if (this.dataDialog.data.changed.length > 0) {
          const obj = this.dataDialog.data.changed.find(o => o.type === 'dataSource');
          if (typeof obj === 'undefined') {
            this.dataDialog.data.changed.push({ type: 'dataSource', alt: true });
          } else {
            obj.alt = true;
          }
        } else {
          this.dataDialog.data.changed = [{ type: 'dataSource', alt: true }];
        }
      }
    );
  }

  onSubmit() {
    const submitData = this.getFormData();
    submitData.selective_process_id = this.dataDialog.data.dataStage.selective_process_id;
    submitData.id = this.dataSource.id;
    submitData.stage_id = this.dataSource.stage_id;
    submitData.type_id = this.queryType;
    submitData.local_id = this.localType;
    submitData.context.filter(value => {
      if (value.field_id) {
        value.field_id = value.field_id.field_id;
      }
    });
    this.serviceQuery.create(submitData).subscribe(
      success => {
        if (success.success) {
          this.updateFormulario(success.data);
          this.loaderService.setChangesLoaderState(false,
            'Fonte de dados salva com sucesso',
            'ok',
            'success');
        } else {
          this.loaderService.setChangesLoaderState(false,
            'Não foi possível configurar a consulta, tente novamente mais tarde !',
            'ok',
            'alert');
        }
      },
      error => {
        this.loaderService.setChangesLoaderState(false,
          error.body,
          '',
          'error',
          error.title,
          100000);
      }
    );
  }

  changeToggle(e) {
    if (!e.checked) {
      if (this.formulario.value.parameters.length === 0) {
        this.addItem({ id: '', name: [''], parameter_type_id: '', field_id: '', fixed_value: '' });
      }
    }
  }

  getFormData() {
    const fullData = this.formulario.getRawValue();
    if (fullData.active_paramn === false) {
      delete fullData.parameters;
    }
    return fullData;
  }

  createItem(i): UntypedFormGroup {
    return this.formBuilder.group({
      id: i.id,
      name: [i.name],
      parameter_type_id: i.parameter_type_id,
      field_id: i.field_id,
      fixed_value: i.fixed_value
    });
  }

  addItem(i): void {
    this.parameters = this.formulario.get('parameters') as UntypedFormArray;
    this.parameters.push(this.createItem(i));
  }

  addItemSpecificPosition(i: number): void {
    const obj = {id: '', name: [''], parameter_type_id: '', field_id: '', fixed_value: ''}
    this.parameters = this.formulario.get('parameters') as UntypedFormArray;
    this.parameters.insert(i + 1,this.createItem(obj));
  }

  removeItem(i): void {
    this.parameters = this.formulario.get('parameters') as UntypedFormArray;
    if (this.parameters.length === 1) {
      this.formulario.patchValue({ active_paramn: false });
    }
    this.parameters.removeAt(i);
  }

  clear(): void {
    this.formulario.reset();
    this.formulario.controls['active_paramn'].setValue(false);
  }

  private updateFormulario(data) {
    if (!data.parameters) {
      data.parameters = [];
    } else {
      data.parameters.filter((e) => {
        e.parameter_type_id = String(e.parameter_type_id);
      });
      this.dataSource.parameters = data.parameters;
    }
    updateObjKeepingRefWithoutMapArray(this.dataSource, data);
    this.dataDialog.data.scheduledClosing = false;
    this.formControlDataSource.changed.patchValue(false);
    if (typeof this.dataDialog.data.changed !== 'undefined') {
      const obj = this.dataDialog.data.changed.find(o => o.type === 'dataSource');
      if (typeof obj !== 'undefined') {
        obj.alt = false;
      }
    }
  }

  private controlValuesContext(element, config) {
    if (element[config.control_name].length > 0) {
      this.formulario.patchValue({ [config.toggle_name]: true });
      const array: Array<any> = element[config.control_name];
      this[config.control_name] = array;
      this.addsPareamentsService.setValuesParameters(array, config.control_name);
    } else {
      if (typeof this[config.control_name] !== 'undefined') {
        this[config.control_name].controls = [];
        this[config.control_name].reset([]);
      }
    }
  }

  get f() { return this.formulario.get('parameters') as UntypedFormArray; }

  get g() { return this.dataSource.standardFields as UntypedFormArray; }
}
