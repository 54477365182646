import { ValidatorFn, UntypedFormGroup, ValidationErrors, UntypedFormArray } from '@angular/forms';

export class SourceFieldsValidator {

  public static validateSourceFields(): ValidatorFn{
    return (group: UntypedFormGroup): ValidationErrors => {
      const colligate = group.controls['colligate'];
      const system = group.controls['system'];
      const code = group.controls['code'];
      const cache = group.controls['use_cache'];
      const interval = group.controls['cache_interval_type_id'];
      const paramsChecked = group.controls['active_paramn'];
      const paramItems = group.get('parameters') as UntypedFormArray;
      if(this.checkEmpty(colligate.value) && this.checkEmpty(system.value) && this.checkEmpty(code.value) && this.checkCache(cache.value, interval.value) && paramsChecked.value == false){
        return null;
      }
      if(this.checkFilled(colligate.value) && this.checkFilled(system.value) && this.checkFilled(code.value) && this.checkCache(cache.value, interval.value) && this.checkParams(paramsChecked.value, paramItems)){
        return null;
      }
      return {fieldNotFilled:true};
    };
  }

  private static checkParams(params, paramItems){
    if(params == true){
      if(this.checkParamItems(paramItems)){
        return true;
      }
      return false
    }
    return true;
  }

  private static checkParamItems(paramItems){
    for(let control of paramItems.controls){
      if(!(control.controls['name'].value &&
      ((control.controls['parameter_type_id'].value == "1" && control.controls['field_id'].value) ||
      (control.controls['parameter_type_id'].value == "2" && control.controls['fixed_value'].value)))){
        return false;
      }
    }
    return true;
  }

  private static checkCache(cache, interval){
    if(!(cache == true && interval == null)){
      return true;
    }
    return false;
  }

  private static checkEmpty(value){
    if(value == null || value == ""){
      return true;
    }
    return false;
  }

  private static checkFilled(value){
    if(value != null && value != ""){
      return true;
    }
    return false;
  }
}
