import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { LoaderService } from '@shared/components/loader/loader.service';
import { uploadProgress, filterResponse } from '@shared/utils/rxjs-operators';
import { SelectiveEditorService } from '@modules/administration/selective-editor/selective-editor.service';
import { DialogComponent } from '@shared/components/dialog/dialog.component';
import { Stage } from '@app/services/selective-processes/stage/stage';
import { Step } from '@app/services/selective-processes/step/step';
import { StepConfigService } from '@app/services/selective-processes/step/step-config.service';

@Component({
  selector: 'app-style-setting-step',
  templateUrl: './style-setting-step.component.html',
  styleUrls: ['./style-setting-step.component.scss']
})
export class StyleSettingStepComponent implements OnInit {

  @Input() dataCurrentStage: Stage;
  @Input() dataCurrentStep: Step;
  @Input() dataSP: any;
  @Output() valueChanged = new EventEmitter<any>();

  public formulario: UntypedFormGroup;
  public typeField;
  public configBackground = {
    title: 'Background',
    buttonAdd: 'Trocar imagem',
    buttonRemove: 'Remover',
    type: 'background',
    textExplanatory: 'A dimensão recomendada é de  1920 x 1080 pixels <br>' +
      '<b>Formato JPEG ou PNG de no máximo 5MB</b> <br>' +
      'Imagens com dimensões diferentes serão redimensionadas',
    acceptedType: 'png,jpeg',
    msgTypeError: 'Formato do background inválido! Tipos validos: JPEG ou PNG.',
    acceptedSize: 5000000,
    msgSizeError: 'Tamanho do background inválido! Tamanho máximo permitido 5MB.',
  };
  public backgroundPortal = '';
  public progress = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dateComponent: DialogComponent,
    private stepConfigService: StepConfigService,
    public selectiveEditorService: SelectiveEditorService,
    private loaderService: LoaderService) {
    this.formulario = this.formBuilder.group({
      background: [null],
      name: ['']
    });
  }

  ngOnInit(): void {
    this.backgroundPortal = this.dataSP.visualIdentity.background;
    this.setValue({back :this.dataCurrentStep.background, name: this.dataCurrentStep.name});

    this.formulario.valueChanges.subscribe(
      changes => {
        this.dataCurrentStep.background = changes.background;
        this.dataCurrentStep.name = changes.name;
        this.valueChanged.emit({ changed: true, to: 'style', data: changes.background });
      }
    );
  }

  onSubmit() {
    this.loaderService.setChangesLoaderState(true);
    if (this.dataCurrentStep.id === null) {
      delete this.dataCurrentStep.id;

      this.stepConfigService.upload(this.dataCurrentStep).pipe(
        uploadProgress(progress => {
          this.progress = progress;
        }),
        filterResponse()
      ).subscribe((response: any) => {
        if (response.success) {
          this.formulario.patchValue({ background: response.data.background, name: response.data.name });
          this.dataCurrentStep.background = response.data.background;
          this.dataCurrentStep.name = response.data.name;
          this.dataCurrentStep.id = response.data.id;
          this.valueChanged.emit({ changed: false, to: 'style', data: null });
          this.loaderService.setChangesLoaderState(false, 'Salvo com sucesso!', 'ok', 'success');
        } else {
          this.loaderService.setChangesLoaderState(false, 'Erro ao salvar, tente novamente.', 'ok', 'error');
        }
      },
        error => {
          this.loaderService.setChangesLoaderState(false,
            error.body,
            '',
            'error',
            error.title,
            100000);
        }
      );
    } else {
      this.stepConfigService.upload(this.dataCurrentStep).pipe(
        uploadProgress(progress => {
          this.progress = progress;
        }),
        filterResponse()
      ).subscribe((response: any) => {
        if (response.success) {
          this.formulario.patchValue({ background: response.data.background , name: response.data.name});
          this.dataCurrentStep.background = response.data.background;
          this.dataCurrentStep.name = response.data.name;
          this.dataCurrentStep.id = response.data.id;
          this.dateComponent.action = 'update';
          this.valueChanged.emit({ changed: false, to: 'style', data: null });
          this.loaderService.setChangesLoaderState(false, 'Salvo com sucesso!', 'ok', 'success');
        } else {
          this.loaderService.setChangesLoaderState(false, 'Erro ao salvar, tente novamente.', 'ok', 'error');
        }
      },
        error => {
          this.loaderService.setChangesLoaderState(false,
            error.body,
            '',
            'error',
            error.title,
            100000);
        }
      );
    }
  }

  private setValue({ back, name }) {
    this.formulario.setValue({ background: back, name });
  }

  get f() { return this.formulario.controls; }
}
