@if (data.type === 'default') {
<div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="100">
        @if ((data?.title | typeof) !== 'undefined') {
        <div fxLayoutAlign="start center">
            <span class="ps-notification-title">{{data.title}}</span>
        </div>
        }
        
        <span class="ps-notification-text" fxLayoutAlign="start center">
            {{ data.msg }}
        </span>
    </div>
    @if (data.act !== '') {
    <div fxFlex fxLayoutAlign="center center" fxFill class="ps-notification-button-content">
        <button mat-button class="ps-notification-button" (click)="closeNotification()">{{ data.act }}</button>
    </div>
    }
</div>
}

@if (data.type === 'success') {
<div fxLayout="row" fxLayoutAlign="center center" class="success">
    <div fxFlex>
        <img src="../../../../assets/images/icons/check.svg" alt="sucesso" class="ps-notification-icon">
    </div>
    <div fxFlex="100">
        @if ((data?.title | typeof) !== 'undefined') {
        <div fxLayoutAlign="start center">
            <span class="ps-notification-title">{{data.title}}</span>
        </div>
        }

        <span class="ps-notification-text" fxLayoutAlign="start center">
            {{ data.msg }}
        </span>
    </div>
    @if (data.act !== '') {
    <div fxFlex fxLayoutAlign="center center" fxFill class="ps-notification-button-content">
        <button mat-button class="ps-notification-button" (click)="closeNotification()">{{ data.act }}</button>
    </div>
    }
</div>
}

@if (data.type === 'alert') {
<div fxLayout="row" fxLayoutAlign="center center" class="alert">
    <div fxFlex>
        <img src="../../../../assets/images/icons/alert.svg" alt="atenção" class="ps-notification-icon">
    </div>
    <div fxFlex="100">
        @if ((data?.title | typeof) !== 'undefined') {
        <div fxLayoutAlign="start center">
            <span class="ps-notification-title">{{data.title}}</span>
        </div>
        }

        <span class="ps-notification-text" fxLayoutAlign="start center">
            {{ data.msg }}
        </span>
    </div>
    @if (data.act !== '') {
    <div fxFlex fxLayoutAlign="center center" fxFill class="ps-notification-button-content">
        <button mat-button class="ps-notification-button" (click)="closeNotification()">{{ data.act }}</button>
    </div>
    }
</div>
}

@if (data.type === 'error') {
<div fxLayout="row" fxLayoutAlign="center center" class="error">
    <div fxFlex>
        <img src="../../../../assets/images/icons/error.svg" alt="error" class="ps-notification-icon">
    </div>
    <div fxFlex="100">
        @if ((data?.title | typeof) !== 'undefined') {
        <div fxLayoutAlign="start center">
            <span class="ps-notification-title">{{data.title}}</span>
        </div>
        }
        
        <span class="ps-notification-text" fxLayoutAlign="start center">
            {{ data.msg }}
        </span>
    </div>
    @if (data.act !== '') {
    <div fxFlex fxLayoutAlign="center center" fxFill class="ps-notification-button-content">
        <button mat-button class="ps-notification-button" (click)="closeNotification()">{{ data.act }}</button>
    </div>
    }
</div>
}