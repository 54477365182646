import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import moment from 'moment';
import { environment } from '@env';
import { take, tap, catchError, map } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { ResourceService } from '@app/http/resource/resource.service';
import { LoaderService } from '@shared/components/loader/loader.service';
import { PageNotAccessService } from '@shared/components/page-not-access/page-not-access.service';
import { SelectiveProcessModel } from '@app/model/selective-process.model';
@Injectable()
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private route: Router,
    private pageNotAccessService: PageNotAccessService,
    private loader: SelectiveProcessModel,
    private loaderService: LoaderService) { }

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {

    if (route.paramMap.get('token') !== null) {
      this.accessAuthentication(route.paramMap.get('token'));
    }
  }

  accessAuthentication(jwt) {
    this.create({jwt}).subscribe(
      success => {
        setTimeout(() => {
          this.pageNotAccessService.makeChanges(true);
          this.setSession(success);
          this.setLocalStorage(success);
          this.route.navigate(['administrativo/home']);
        }, 3000);
      },
      error => {
        this.logout();
      }
    );
  }

  private setLocalStorage(authResult) {
    localStorage.removeItem('user');
    localStorage.setItem('tokenPS', authResult.token);
    localStorage.setItem('user', authResult.data.user_name);
    localStorage.setItem('crm', authResult.data.crm);
    localStorage.setItem('email', authResult.data.user_email);
  }

  private setSession(authResult) {
    sessionStorage.removeItem('user');
    sessionStorage.setItem('tokenPS', authResult.token);
    sessionStorage.setItem('user', authResult.data.user_name);
    sessionStorage.setItem('crm', authResult.data.crm);
    sessionStorage.setItem('email', authResult.data.user_email);
  }

  public logout() {
    this.loaderService.setChangesLoaderState(true);
    location.assign(localStorage.getItem('crm'));
    localStorage.removeItem('tokenPS');
    localStorage.removeItem('crm');
    localStorage.removeItem('email');
  }


  private create(item): Observable<any> {

    return this.httpClient
      .post(`${environment.API}/auth`, item)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      console.error(error);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
