import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientLoaderService {

  public loaderControl = new BehaviorSubject(false);
  public color: string;

  constructor() { }


  getChangesLoaderState(): Observable<any> {
    return this.loaderControl.asObservable();
  }

  setChangesLoaderState(value: any) {
    this.loaderControl.next(value);
  }

  setColor(param) {
    this.color = param;
  }
}
