import { Component, OnInit, Input, Injectable, SimpleChanges, OnChanges, OnDestroy, AfterViewInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Fields } from '@app/services/selective-processes/fields/fields';
import {
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
  ValidatorFn,
  AbstractControl,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  UntypedFormBuilder
} from '@angular/forms';
import { Observable } from 'rxjs';
import { DataSourceTypeFieldValidator } from '@shared/validators/data-source-type-field-validator';
import { startWith, map } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { AddsPareamentsService } from './adds-pareaments.service';
import { MatSelect } from '@angular/material/select';
import { filterSystemFields } from '@shared/utils/util';

interface Iidentification {
  toggle_name: string,
  control_name: string
}

export class FormCustomValidators {
  static valueSelected(myArray: any[]): ValidatorFn {

    return (c: AbstractControl): { [key: string]: boolean } | null => {
      const selectboxValue = c.value?.label;
      const pickedOrNot = myArray.filter(alias => alias.label === selectboxValue);
      if (c.parent) {
        if (c.value) {
          if (pickedOrNot.length > 0) {
            return null;
          } else {
            return { match: true };
          }
        } else {
          return null;
        }
      }
    };
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

@Component({
  selector: 'app-adds-pareaments',
  templateUrl: './adds-pareaments.component.html',
  styleUrls: ['./adds-pareaments.component.scss']
})
export class AddsPareamentsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() identification: Iidentification = {
    control_name: 'parameters',
    toggle_name: 'active_paramn'
  }
  @Input() systemFields: Fields[] = new Array();
  @Input() formulario: UntypedFormGroup;
  @Input() parameters: UntypedFormArray;
  @Input() nameFields = new Array();
  @Input() nameFieldsDisabled = false;
  @Input() toggle = false;
  @Input() fxFlex = 20;
  @Input() nameToggle = 'Enviar parâmetros';
  @Input() disableOnDestroyFunctions = false;
  @Input() clearParametersOnUncheckToggle = false;

  public fieldsKey = [];
  public dataSource: [];
  public haveDataSource: boolean;
  public actived;
  public matcher = new MyErrorStateMatcher();
  public filteredOptions: Observable<Fields[]>[] = [];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private addsPareamentsService: AddsPareamentsService,
    public changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.toggle && this.f[this.i.control_name].value.length === 0) {
      this.addItem();
    }
    this.disabledSelectedContext();
    this.setClearParameters();
  }

  createItem(): UntypedFormGroup {
    return this.formBuilder.group({
      id: '',
      fixed_param: '',
      name: [{ value: '', disabled: this.nameFields.length > 0 ? false : true }, [Validators.required]],
      parameter_type_id: [null, Validators.required],
      field_id: ['', [DataSourceTypeFieldValidator.valueFieldId(), FormCustomValidators.valueSelected(this.systemFields)]],
      fixed_value: ['', DataSourceTypeFieldValidator.valueFixedValue()]
    });
  }

  /**
   * removesSelectedContext
   */
  public disabledSelectedContext() {
    if (this.nameFieldsDisabled) {
      const interval = setInterval(() => {
        if (this.nameFields.length > 0 && this.parameters) {
          this.nameFields.map(value => {
            let selectedValue = false;
            this.parameters.value.map(valueTwo => {
              if (value.name === valueTwo.name) {
                selectedValue = true;
              }
            })
            value.disabled = selectedValue;
          });
          clearInterval(interval);
        }
      }, 100)
    }
  }

  /**
   * disableAddNewContextButton
   */
  public disableAddNewContextButton() {
    if (this.nameFields.length > 0) {
      return this.nameFields.every(value => value.disabled === true);
    } else {
      return false;
    }
  }


  changeParameterTypeId() {
    this.parameters.controls.forEach(control => {
      const controlAny: any = control;
      controlAny.controls.fixed_value.updateValueAndValidity();
      controlAny.controls.field_id.updateValueAndValidity();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nameFields && !changes.nameFields.firstChange) {
      if (typeof this.addsPareamentsService.array[this.i.control_name] === 'undefined') {
        this.removeItem(0);
      } else {
        if (this.addsPareamentsService.array[this.i.control_name].length === 0) {
          this.removeItem(0);
        }
      }
    } else {
      if (typeof this.addsPareamentsService.array[this.i.control_name] !== 'undefined') {
        this.addItem(this.addsPareamentsService.array[this.i.control_name]);
      }
    }
  }

  addItem(array: Array<any> = [], i:any = null): void {
    this.parameters = this.p;
    if (array.length === 0) {
      if(i != null) {
        this.parameters.insert(i + 1,this.createItem());
        this.manageNameControl(i);
      } else {
        this.parameters.push(this.createItem());
        this.manageNameControl(this.parameters.length - 1);
      }
    } else {
      for (const key in array) {
        if (array.hasOwnProperty(key)) {
          const element = array[key];
          this.parameters.push(this.formBuilder.group({
            id: element.id,
            fixed_param: element.fixed_param,
            name: [{ value: element.name, disabled: this.nameFields.length > 0 ? false : true }, Validators.required],
            parameter_type_id: [element.parameter_type_id, Validators.required],
            field_id: [
              this.buildSelectedAutocompleteOption(element.field_id, this.systemFields),
              [
                FormCustomValidators.valueSelected(this.systemFields),
                DataSourceTypeFieldValidator.valueFieldId()
              ]
            ],
            fixed_value: [element.fixed_value, DataSourceTypeFieldValidator.valueFixedValue()],
          }));
          this.manageNameControl(this.parameters.length - 1);
        }
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  manageNameControl(index: number) {
    if(index === this.parameters.length - 1) {
      this.filteredOptions[index] = this.parameters.at(index).get('field_id').valueChanges
      .pipe(
        startWith(''),
        map(option => option ?  filterSystemFields({ value: option, systemFields: this.systemFields}) : this.systemFields.slice())
      );
    } else {
      const filteredOptions = [...this.filteredOptions];
      const newsElements = [
        Object.assign(filteredOptions[index]),
        this.parameters.at(index + 1).get('field_id').valueChanges.pipe(
          startWith(''),
          map(option => option ? filterSystemFields({ value: option, systemFields: this.systemFields}) : this.systemFields.slice())
        )];
        filteredOptions.splice(index, 1,...newsElements);
        this.filteredOptions = filteredOptions;
      }
  }

  buildSelectedAutocompleteOption(id, options) {
    if (typeof id !== 'object') {
      if (options && options.length) {
        for (const iterator of options) {
          if (iterator.field_id === id) {
            return iterator;
          }
        }
      }
      return null;
    } else {
      return id;
    }
  }

  removeItem(i): void {
    const param = this.p;
    if (param.length === 1) {
      this.formulario.patchValue({ [this.i.toggle_name]: false });
    }
    param.removeAt(i);
    this.disabledSelectedContext();
  }

  /**
   * changeToggle
   */
  public changeToggle(e) {
    if (!e.checked) {
      this.setValidatorsParameters();
      if (this.formulario.value[this.i.control_name].length === 0) {
        this.addItem();
      }
    } else {
      if (typeof this.parameters !== 'undefined') {
        const param = this.p;
        if (typeof param.controls !== 'undefined') {
          param.controls.map((ctrl: any) => {
            for (const key in ctrl.controls) {
              if (ctrl.controls.hasOwnProperty(key)) {
                const element = ctrl.controls[key];
                element.clearValidators();
                element.updateValueAndValidity();
              }
            }
          });
        }
      }
    }
  }


  public disabled() {
    if (this.toggle) {
      if (this.f[this.i.control_name].value.length === 1) {
        return true;
      }
    } else {
      return false;
    }
  }

  private setValidatorsParameters() {
    const param = this.p;
    if (typeof param !== 'undefined') {
      param.controls.map((ctrl: any) => {
        for (const key in ctrl.controls) {
          if (ctrl.controls.hasOwnProperty(key)) {
            const element = ctrl.controls[key];

            if (key === 'name') {
              element.setValidators(Validators.required);
              // element.updateValueAndValidity();
            }
            if (key === 'parameter_type_id') {
              element.setValidators(Validators.required);
              // element.updateValueAndValidity();
            }
            if (key === 'fixed_value') {
              element.setValidators(DataSourceTypeFieldValidator.valueFixedValue());
              // element.updateValueAndValidity();
            }
            if (key === 'field_id') {
              // tslint:disable-next-line: max-line-length
              element.setValidators([DataSourceTypeFieldValidator.valueFieldId(), FormCustomValidators.valueSelected(this.systemFields)]);
              // element.updateValueAndValidity();
            }
            element.markAsUntouched();
            // element.markAsPristine();
          }
        }
      });
    }
  }

  private setClearParameters() {
    if(this.clearParametersOnUncheckToggle) {
      this.t.valueChanges.subscribe((value: any) => {
        if(!value) {
          this.clearParameters();
          this.disabledSelectedContext();
        }
      });
    }
  }

  private clearParameters() {
    this.p.clear();
  }

  displayFn(field?: Fields): string | undefined {
    return field ? field.label : undefined;
  }

  ngOnDestroy(): void {
    if (!this.disableOnDestroyFunctions) {
      this.addsPareamentsService.setValuesParameters(undefined, this.i.control_name);
    }
  }

  get p() { return this.formulario.get(this.i.control_name) as UntypedFormArray; }
  get t() { return this.formulario.get(this.i.toggle_name) as UntypedFormArray; }
  get f() { return this.formulario.controls; }
  get i() { return this.identification as Iidentification; }
}
