import { NgIf } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TypeofPipe } from '@shared/pipe/typeof.pipe';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports:[
    NgIf,
    FlexLayoutModule,
    MatButtonModule,
    TypeofPipe
  ]
})
export class NotificationComponent implements OnInit {

  data: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public dataSnack: any, private matSnackBar: MatSnackBar) {
    this.data = dataSnack;
  }

  closeNotification() {
    this.matSnackBar.dismiss();
  }

  ngOnInit() {
  }

}
